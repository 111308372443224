/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global google, mapData */

// Googlemap: Implements Google Map

import $ from 'jquery';

export class Googlemap {
	constructor() {
		this.styles = [
				{
					featureType: "water",
					elementType: "all",
					stylers: [
					{ "color": "#8c8c8c" }
					]
				},
				{
					featureType: "landscape",
					elementType: "all",
					stylers: [
					{ "color": "#E4E3E2" }
					]
				},
				{
					featureType: "poi",
					elementType: "geometry",
					stylers: [
						{ "color": "#E4E3E2" }
					]
				},
				{
					featureType: 'poi',
					elementType: "labels",
					stylers: [
						{visibility: 'off'}
					]
				},
				{
					featureType: 'transit',
					elementType: 'labels.icon',
					stylers: [
						{visibility: 'off'}
					]
				},
				{
					featureType: "road",
					elementType: "geometry.fill",
					stylers: [
					{ "color": "#FFFFFF" }
					]
				},
				{
					featureType: "road",
					elementType: "geometry.stroke",
					stylers: [
					{ "color": "#FFFFFF" }
					]
				},
				{
					featureType: "road.highway",
					elementType: "labels.text.stroke",
					stylers: [
					{ "color": "#848484" }
					]
				},
				{
					featureType: "road.highway",
					elementType: "labels.text.fill",
					stylers: [
					{ "color": "#FFFFFF" }
					]
				},
				{
					featureType: "transit",
					elementType: "labels.text.fill",
					stylers: [
					{ "color": "#000000" }
					]
				}
			];

			if (document.getElementById('googlemap')) {
				google.maps.event.addDomListener(window, 'load', this.init.bind(this));
			}
	}

	init() {
		const _this = this;

		// Create a new StyledMapType object, passing it the array of styles as well as the name to be displayed on the map type control.
		const styledMap = new google.maps.StyledMapType(_this.styles, {name: 'Riverside Capital'});
		const markerElement = $('.marker');

		let markerLocation;

		if ( markerElement.length ) {
			markerLocation = {
				lat: markerElement.attr('data-lat'),
				lng: markerElement.attr('data-lng')
			}
		}

		// if marker has lat-lang, set it. If not default location (Riverside HQ)
		let myLatlng = new google.maps.LatLng(51.5281036,-0.0910538);

		if ( markerLocation ) {
			myLatlng = new google.maps.LatLng(markerLocation.lat,markerLocation.lng);
		}

		// Create a map object, and include the MapTypeId to add to the map type control.
		let mapOptions = {
			zoom: 16,
			center: myLatlng,
			scrollwheel: false,
			mapTypeControl: false,
			panControl: false,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
			}
		};

		const map = new google.maps.Map(document.getElementById('googlemap'), mapOptions);

		const icon = {
			url: mapData['templateDirectory'] + '/library/images/map_marker.svg',
			scaledSize: new google.maps.Size(55, 55) // scaled size
		}

		const iconHover = {
			url: mapData['templateDirectory'] + '/library/images/map_marker--hover.svg',
			scaledSize: new google.maps.Size(55, 55) // scaled size
		}

		// setup marker
		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			icon: icon,
			title: 'Click to zoom'
		});

		// add hover state version of marker on mouseover
		google.maps.event.addListener(marker, 'mouseover', function() {
			marker.setIcon(iconHover);
		});

		// return to normal version on mouseout
		google.maps.event.addListener(marker, 'mouseout', function() {
			marker.setIcon(icon);
		});

		// if marker is clicked, zoom 2 levels into marker center
		marker.addListener('click', function() {
			map.setZoom(map.getZoom() + 2);
			map.setCenter(marker.getPosition());
		});

		// if map is resized, re-centre map
		google.maps.event.addDomListener(window, "resize", function() {
			var center = map.getCenter();
			google.maps.event.trigger(map, "resize");
			map.setCenter(center);
		});

		// Associate the styled map with the MapTypeId and set it to display.
		map.mapTypes.set('map_style', styledMap);
		map.setMapTypeId('map_style');
	}
}