/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global mapData */

// Animation: Uses lottie-web to animate the logo

import $ from 'jquery';
import lottie from 'lottie-web';
import Cookies from 'js-cookie';

export class Animation {
	constructor() {
  	this.hasStartedSession = Cookies.get('riverside-active-session');
  	this.cookieExpirationInDays = 1;

  	if ($('#logo--colour').length) {
      this.logo = {
      	container: document.getElementById('logo--colour'),
      	renderer: 'svg',
      	loop: false,
      	autoplay: false,
      	path: mapData['templateDirectory'] + '/library/images/logo--colour.json' // the path to the animation json
      }
  	} else if ($('#logo--white').length) {
    	this.logo = {
      	container: document.getElementById('logo--white'),
      	renderer: 'svg',
      	loop: false,
      	autoplay: false,
      	path: mapData['templateDirectory'] + '/library/images/logo--white.json' // the path to the animation json
      }
  	}
	}

	init() {
  	const _this = this;

  	if (_this.logo) {
  	  _this.animatedLogo = lottie.loadAnimation(_this.logo);

  	  _this.animatedLogo.addEventListener('data_ready', function() {
        _this.animatedLogo.goToAndStop(_this.animatedLogo.totalFrames - 1, true);
        _this.bind();

        if (!_this.hasStartedSession) {
          _this.animatedLogo.goToAndPlay(0, true);
          Cookies.set('riverside-active-session', true, { expires: _this.cookieExpirationInDays });
  	    }
  	  });
    }
	}

	bind() {
  	const _this = this;

  	$('#logo--colour, #logo--white').on('mouseenter', function() { // Play animation from first frame on hover
    	_this.animatedLogo.goToAndPlay(0, true);
  	});
	}
}