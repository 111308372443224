/* jshint esversion: 6, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global document */

import $ from 'jquery';

import { Animation } from './modules/animation.js';
import { Googlemap } from './modules/googlemap.js';

class Site {
	constructor() {
		this.animation = new Animation();
		this.googlemap = new Googlemap();

		$(document).ready(this.onReady.bind(this));
	}

	onReady() {
		const _this = this;

		_this.animation.init();

		_this.updateDOM();
		_this.bind();
	}

	updateDOM() {
    $(".term-and-conditions").html('<a href="#" name="terms" style="border-bottom: 1px solid #AE9A64;">terms and conditions.*</a>');
    $(".unregulated-property-investment").html('<a href="#" name="unregulated-property-investment" style="border-bottom: 1px solid #AE9A64;">risks associated in investing in unregulated property funds.*</a>');

    $(".term-and-conditions").html(); // what does this function do here? doesnt seem to be doing anything?
    $(".unregulated-property-investment").html();
    $(".hnwi-investor-info").html();
	}

	bind() {
  	$('.term-and-conditions').click( function() {
  		$(".modal-bg").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.unregulated-property-investment').click( function() {
  		$(".modal-bg-unregulated").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.experienced-investor-info').click( function() {
  		$(".modal-bg-investor").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.hnwi-investor-info').click( function() {
  		$(".modal-bg-hnwi").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.close-terms').click( function() {
  		$(".modal-bg").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.close-terms-risk').click( function() {
  		$(".modal-bg-unregulated").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.close-terms-investor').click( function() {
  		$(".modal-bg-investor").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.close-terms-hnwi').click( function() {
  		$(".modal-bg-hnwi").toggleClass("modal-open");
  		$("html").toggleClass("modal-open");
  	} );

  	$('.filter').click( function(){
  		$(this).toggleClass('filter--active');
  	})

  	if ($(".header-image").length) {
      $('.header-container').addClass('header-container--overlay');
  	}

  	var $openPanel = $(".open-panel"),
  		$closePanel = $(".close-panel"),
  		$content = $('#content'),
  		$htmlElement = $('html'),
  		$trackRecordArchive = $('.trackrecord-archive');

  	if ($openPanel.length) {
  		$(".open-panel").click(function () {
  			$htmlElement.toggleClass("openNav");
  		});
  	}

  	if ($closePanel.length || $content.length) {
  		$(".close-panel, #content").click(function () {
  			$htmlElement.removeClass("openNav");
  		});
  	}

  	$trackRecordArchive.on('mouseleave', function() {
  		if ($(window).width() >= 480) {
  			var $moreText = $($(this).find('.more')[0]);
  			var $overlayText = $($(this).children('.overlay-text')[0]);

  			$moreText.stop();
  			$overlayText.stop();

  			$moreText.animate({
  				opacity: 0
  			}, 100, function () {
  				$moreText.animate({
  					// $moreText starts with height of zero and margin-top/bottom of zero
  					height: 0,
  					'margin-top': 0
  				}, 100);
  			});
  		}
  	});

  	$trackRecordArchive.on('mouseenter', function() {
  		if ($(window).width() >= 480) {
  			var $moreText = $($(this).find('.more')[0]);
  			var $overlayText = $($(this).children('.overlay-text')[0]);

  			$moreText.stop();
  			$overlayText.stop();

  			$moreText.animate({
  				// $moreText starts with height of zero and margin-top/bottom of zero
  				height: 20,
  				'margin-top': 5,
  				'margin-bottom': 8
  			}, 200, function () {
  				$moreText.animate({
  					opacity: 1
  				}, 100);
  			});
  		}
  	});

	}
}

new Site();